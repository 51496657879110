<!-- ============================================================== -->
<!-- toggle and nav items -->
<!-- ============================================================== -->
<ul class="navbar-nav float-left mr-auto">
    <li class="nav-item d-none d-lg-block">
        <a (click)="toggleSidebar.emit()" class="nav-link sidebartoggler waves-effect waves-light" href="javascript:void(0)">
            <i class="mdi mdi-menu font-24"></i>
        </a>
    </li>
    <!-- ============================================================== -->
    <!-- mega menu -->
    <!-- ============================================================== -->
    <!-- <li class="nav-item mega-dropdown" ngbDropdown [autoClose]="false">
        <a ngbDropdownToggle class="nav-link waves-effect waves-dark" href="javascript:void(0)" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            <span class="d-none d-md-block">Mega
                <i class="fa fa-angle-down"></i>
            </span>
            <span class="d-block d-md-none">
                <i class="mdi mdi-dialpad font-24"></i>
            </span>
        </a>
        <div class="dropdown-menu" ngbDropdownMenu>
            <div class="mega-dropdown-menu row">
                <div class="col-lg-3 col-xlg-2 m-b-30">
                    <h4 class="m-b-20">CAROUSEL</h4>
                    CAROUSEL
                    <ngb-carousel>
                        <ng-template ngbSlide>
                            <img src="assets/images/big/img1.jpg" class="img-fluid" alt="Random first slide">
                            <div class="carousel-caption">
                                <h3 class="text-white font-bold">First slide label</h3>
                            </div>
                        </ng-template>
                        <ng-template ngbSlide>
                            <img src="assets/images/big/img2.jpg" class="img-fluid" alt="Random second slide">
                            <div class="carousel-caption">
                                <h3 class="text-white font-bold">Second slide label</h3>
                            </div>
                        </ng-template>
                        <ng-template ngbSlide>
                            <img src="assets/images/big/img3.jpg" class="img-fluid" alt="Random third slide">
                            <div class="carousel-caption">
                                <h3 class="text-white font-bold">Third slide label</h3>
                            </div>
                        </ng-template>
                    </ngb-carousel>
                    End CAROUSEL
                </div>
                <div class="col-lg-3 m-b-30">
                    <h4 class="m-b-20">ACCORDION</h4>
                    Accordian
                    <ngb-accordion [closeOthers]="true" activeIds="static-1" class="accordion nav-accordion">
                        <ngb-panel id="static-1" title="Simple">
                            <ng-template ngbPanelContent>
                                Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad
                                squid.
                            </ng-template>
                        </ngb-panel>
                        <ngb-panel id="static-2">
                            <ng-template ngbPanelTitle>
                                <span>&#9733;
                                    <b>Fancy</b> title &#9733;</span>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad
                                squid.
                            </ng-template>
                        </ngb-panel>
                        <ngb-panel id="static-3" title="Disabled" [disabled]="true">
                            <ng-template ngbPanelContent>
                                Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad
                                squid.
                            </ng-template>
                        </ngb-panel>
                    </ngb-accordion>
                </div>
                <div class="col-lg-3  m-b-30">
                    <h4 class="m-b-20">CONTACT US</h4>
                    Contact
                    <form>
                        <div class="form-group">
                            <input type="text" class="form-control" id="exampleInputname1" placeholder="Enter Name">
                        </div>
                        <div class="form-group">
                            <input type="email" class="form-control" placeholder="Enter email"> </div>
                        <div class="form-group">
                            <textarea class="form-control" id="exampleTextarea" rows="3"
                                placeholder="Message"></textarea>
                        </div>
                        <button type="submit" class="btn btn-info">Submit</button>
                    </form>
                </div>
                <div class="col-lg-3 col-xlg-4 m-b-30">
                    <h4 class="m-b-20">List style</h4>
                    List style
                    <ul class="list-style-none">
                        <li>
                            <a href="javascript:void(0)">
                                <i class="fa fa-check text-success"></i> You can give link</a>
                        </li>
                        <li>
                            <a href="javascript:void(0)">
                                <i class="fa fa-check text-success"></i> Give link</a>
                        </li>
                        <li>
                            <a href="javascript:void(0)">
                                <i class="fa fa-check text-success"></i> Another Give link</a>
                        </li>
                        <li>
                            <a href="javascript:void(0)">
                                <i class="fa fa-check text-success"></i> Forth link</a>
                        </li>
                        <li>
                            <a href="javascript:void(0)">
                                <i class="fa fa-check text-success"></i> Another fifth link</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </li> -->
    <!-- ============================================================== -->
    <!-- End mega menu -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- create new -->
    <!-- ============================================================== -->
    <!-- <li class="nav-item dropdown" ngbDropdown>
        <a ngbDropdownToggle class="nav-link dropdown-toggle" href="javascript:void(0)" id="navbarDropdown"
            role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <span class="d-none d-md-block">Create New
                <i class="fa fa-angle-down"></i>
            </span>
            <span class="d-block d-md-none">
                <i class="fa fa-plus"></i>
            </span>
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown" ngbDropdownMenu>
            <a class="dropdown-item" href="#">Action</a>
            <a class="dropdown-item" href="#">Another action</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" href="#">Something else here</a>
        </div>
    </li> -->
    <!-- ============================================================== -->
    <!-- Search -->
    <!-- ============================================================== -->
    <!-- <li class="nav-item search-box">
        <a (click)="showSearch = !showSearch" class="nav-link waves-effect waves-dark" href="javascript:void(0)">
            <i class="ti-search"></i>
        </a>
        <form class="app-search position-absolute" [ngClass]="{'show-search': showSearch}">
            <input type="text" class="form-control" placeholder="Search &amp; enter">
            <a class="srh-btn" (click)="showSearch = !showSearch">
                <i class="ti-close"></i>
            </a>
        </form>
    </li> -->
</ul>
<!-- ============================================================== -->
<!-- Right side toggle and nav items -->
<!-- ============================================================== -->
<ul class="navbar-nav float-right">
    <!-- ============================================================== -->
    <!-- create new -->
    <!-- ============================================================== -->
    <!-- <li class="nav-item dropdown" ngbDropdown placement="bottom-right">
        <a ngbDropdownToggle class="nav-link dropdown-toggle" href="javascript:void(0)" id="navbarDropdown"
            role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="flag-icon flag-icon-{{selectedLanguage.icon}}"></i>
        </a>
        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown" ngbDropdownMenu>
            <span class="dropdown-item" href="#" *ngFor="let lang of languages" (click)="changeLanguage(lang)">

                <i class="flag-icon flag-icon-{{lang.icon}} mr-2"></i>
                <span>{{lang.language}}
                    <span *ngIf="lang.type">({{lang.type}})</span>
                </span>

            </span>


            <i class="flag-icon flag-icon-us"></i> English</a>
            <a class="dropdown-item" href="#">
                <i class="flag-icon flag-icon-fr"></i> French</a>
            <a class="dropdown-item" href="#">
                <i class="flag-icon flag-icon-es"></i> Spanish</a>
            <a class="dropdown-item" href="#">
                <i class="flag-icon flag-icon-de"></i> German</a>
        </div>
    </li> -->
    <!-- ============================================================== -->
    <!-- Comment -->
    <!-- ============================================================== -->
    <!-- <li class="nav-item dropdown" ngbDropdown placement="bottom-right">
        <a ngbDropdownToggle class="nav-link dropdown-toggle waves-effect waves-dark" href="javascript:void(0)"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="mdi mdi-bell font-24"></i>
        </a>
        <div class="dropdown-menu dropdown-menu-right mailbox" ngbDropdownMenu>
            <span class="with-arrow">
                <span class="bg-primary"></span>
            </span>
            <ul class="list-style-none">
                <li>
                    <div class="drop-title bg-primary text-white">
                        <h4 class="m-b-0 m-t-5">4 New</h4>
                        <span class="font-light">Notifications</span>
                    </div>
                </li>
                <li>
                    <div class="message-center notifications" [perfectScrollbar]="config">
                        Message
                        <a href="javascript:void(0)" class="message-item" *ngFor="let notification of notifications">
                            <span class="btn btn-circle {{notification.btn}}">
                                <i class="{{notification.icon}}"></i>
                            </span>
                            <span class="mail-contnet">
                                <h5 class="message-title">{{notification.title}}</h5>
                                <span class="mail-desc">{{notification.subject}}</span>
                                <span class="time">{{notification.time}}</span>
                            </span>
                        </a>
                    </div>
                </li>
                <li>
                    <a class="nav-link text-center m-b-5" href="javascript:void(0);">
                        <strong>Check all notifications</strong>
                        <i class="fa fa-angle-right"></i>
                    </a>
                </li>
            </ul>
        </div>
    </li> -->
    <!-- ============================================================== -->
    <!-- End Comment -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- Messages -->
    <!-- ============================================================== -->
    <!-- <li class="nav-item dropdown" ngbDropdown placement="bottom-right">
        <a ngbDropdownToggle class="nav-link dropdown-toggle waves-effect waves-dark" href="javascript:void(0)" id="2"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="font-24 mdi mdi-comment-processing"></i>

        </a>
        <div class="dropdown-menu dropdown-menu-right mailbox" aria-labelledby="2" ngbDropdownMenu>
            <span class="with-arrow">
                <span class="bg-danger"></span>
            </span>
            <ul class="list-style-none">
                <li>
                    <div class="drop-title text-white bg-danger">
                        <h4 class="m-b-0 m-t-5">5 New</h4>
                        <span class="font-light">Messages</span>
                    </div>
                </li>
                <li>
                    <div class="message-center message-body" [perfectScrollbar]="config">
                        Message
                        <a href="javascript:void(0)" class="message-item" *ngFor="let mymessage of mymessages">
                            <span class="user-img">
                                <img src="{{mymessage.useravatar}}" alt="user" class="rounded-circle">
                                <span class="profile-status {{mymessage.status}} pull-right"></span>
                            </span>
                            <span class="mail-contnet">
                                <h5 class="message-title">{{mymessage.from}}</h5>
                                <span class="mail-desc">{{mymessage.subject}}</span>
                                <span class="time">{{mymessage.time}}</span>
                            </span>
                        </a>
                    </div>
                </li>
                <li>
                    <a class="nav-link text-center link" href="javascript:void(0);">
                        <b>See all e-Mails</b>
                        <i class="fa fa-angle-right"></i>
                    </a>
                </li>
            </ul>
        </div>
    </li> -->
    <!-- ============================================================== -->
    <!-- End Messages -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- User profile and search -->
    <!-- ============================================================== -->
    <li class="nav-item" ngbDropdown placement="bottom-right">
        <a ngbDropdownToggle class="nav-link text-muted waves-effect waves-dark pro-pic" href="javascript:void(0)" aria-haspopup="true" aria-expanded="false">
            <img src="assets/images/users/1.jpg" alt="user" class="rounded-circle" width="31">
        </a>
        <div class="dropdown-menu-right user-dd" ngbDropdownMenu>
            <span class="with-arrow">
                <span class="bg-primary"></span>
            </span>
            <div class="d-flex no-block align-items-center p-15 bg-primary text-white m-b-10">
                <div class="">
                    <img src="assets/images/users/1.jpg" alt="user" class="img-circle" width="60">
                </div>
                <div class="m-l-10">
                    <h4 class="m-b-0">{{userData.name}}</h4>
                    <!-- <h4 class="m-b-0">{{userData.name}}</h4> -->

                    <!-- <p class=" m-b-0">varun@gmail.com</p> -->
                </div>
            </div>
            <!-- <a class="dropdown-item" href="javascript:void(0)">
                <i class="ti-user m-r-5 m-l-5"></i> My Profile</a>
            <a class="dropdown-item" href="javascript:void(0)">
                <i class="ti-wallet m-r-5 m-l-5"></i> My Balance</a>
            <a class="dropdown-item" href="javascript:void(0)">
                <i class="ti-email m-r-5 m-l-5"></i> Inbox</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" href="javascript:void(0)">
                <i class="ti-settings m-r-5 m-l-5"></i> Account Setting</a> -->
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" href="javascript:void(0)" (click)="logOut()">
                <i class="fa fa-power-off m-r-5 m-l-5"></i> Logout</a>

            <div class="dropdown-divider"></div>
            <!-- <div class="p-l-30 p-10">
                <a href="javascript:void(0)" class="btn btn-sm btn-success btn-rounded">View Profile</a>
            </div> -->
        </div>
    </li>
    <!-- ============================================================== -->
    <!-- User profile and search -->
    <!-- ============================================================== -->
</ul>