import {
  Component,
  Input,
  OnDestroy,
  Inject,
  ViewEncapsulation
} from '@angular/core';
import {
  Router,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError
} from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-spinner',
  template: `<div class="preloader" *ngIf="isSpinnerVisible">
        <div class="spinner">
          <div class="double-bounce1"></div>
          <div class="double-bounce2"></div>
        </div>
    </div>`,
  encapsulation: ViewEncapsulation.None
})
export class SpinnerComponent implements OnDestroy {
  public isSpinnerVisible = false;

  @Input() public backgroundColor = 'rgba(0, 115, 170, 0.69)';

  // constructor(private router: Router, @Inject(DOCUMENT) private document: Document,private spinner : NgxSpinnerService) {
  //   console.log('document',this.document);
    
  //   debugger
  //   this.router.events.subscribe(
  //     event => {
  //       if (event instanceof NavigationStart) {
  //         this.spinner.show()

  //       } 
  //       else if (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
  //         // this.isSpinnerVisible = false;
  //         this.spinner.hide()

  //       }
  //     },      
  //     () => {
  //       // this.isSpinnerVisible = false;
  //       // this.spinner.hide()

  //     }
  //   );
  // }

  ngOnDestroy(): void {
    this.isSpinnerVisible = false;
  }
}
