// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  API_URL : "https://api.getmyrx.ca/v2/",
  AUTH_URL : "https://api.getmyrx.ca/"
  // API_URL : "http://devapi.getmyrx.ca/v2/",
  // AUTH_URL : "http://devapi.getmyrx.ca/"
  //API_URL : "http://localhost:4444/v2/",
  //AUTH_URL : "http://localhost:4444/"
  //API_URL : "http://localhost:4000/v2/",
  //AUTH_URL : "http://localhost:4000/"
  // API_URL : "http://192.168.182.128:4000/v2/",
  // AUTH_URL : "http://192.168.182.128:4000/"
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
