import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import {Title} from "@angular/platform-browser";
@Injectable({
  providedIn: 'root'
})
export class CommomService {
  API_URL= environment.API_URL
  AUTH_URL= environment.AUTH_URL

  pharmacyData :any;
  spinnerFlag = new Subject<boolean>();
  isGetMyRx : any;
  favIcon: HTMLLinkElement = document.querySelector('#link') as HTMLLinkElement;

  isPymentModalCliked = new Subject<any>();

  messageSource = new BehaviorSubject("default message");
  openMyCustomerModal = new BehaviorSubject("default message");

  constructor(private http: HttpClient,private titleService:Title) {
    this.pharmacyData = JSON.parse(localStorage.getItem('userData') || '{}')
    console.log('this.pharmacyData ',this.pharmacyData );

   }

  authentication(data:any , encdata : any): Observable<any>{
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded').set('Authorization','Basic '+encdata);
    let body = new URLSearchParams();
    body.set('grant_type', data.grant_type);
    body.set('client_id', data.client_id);
    body.set('client_secret', data.client_secret);
    return this.http.post(this.AUTH_URL + "oauth2/token",body.toString(),{headers: headers})
  }

  logIn(data:any): Observable<any>{
      return this.http.post(this.API_URL + "pharmacy/login",data)
  }

  getDeliverDetailsLocation():Observable<any>{
    let pharmacyData = JSON.parse(localStorage.getItem('userData') || '{}')
    return this.http.get(this.API_URL+'pharmacy/deliveryboy/'+pharmacyData.pharmacy_id);
  }

  getTodaysDeliveryData(start : any , length : any ,from_date : any ,to_date : any,search : any, dtype : any , status : any):Observable<any>{

    console.log(localStorage.getItem('userData'));

    let pharmacyData = JSON.parse(localStorage.getItem('userData') || '{}')
    return this.http.get(this.API_URL+'delivery/dashboard/'+pharmacyData.pharmacy_id+'/1',{
      params: {
        start,
        length,
        from_date,
        to_date,
        search,
        dtype,
        status
    }}
    )
  }

  addPaidAmount(data:any, pharmacy_id:any): Observable<any>{
    let pharmacyData = JSON.parse(localStorage.getItem('userData') || '{}')
    console.log("Parmacy Id"+pharmacy_id)
    return this.http.post(this.API_URL + "delivery/updatedeliveryamount/"+pharmacy_id, data)
  }

  getTodaysDeliveryHistoryData(start : any , length : any ,from_date : any ,to_date : any,search : any, dtype : any , status : any):Observable<any>{

    // console.log("Data"+localStorage.getItem('userData'));

    let pharmacyData = JSON.parse(localStorage.getItem('userData') || '{}')
    return this.http.get(this.API_URL+'delivery/dashboard/'+pharmacyData.pharmacy_id,{
      params: {
        // start,
        // length,
        // from_date,
        // to_date
        start,
        length,
        from_date,
        to_date,
        search,
        dtype,
        status
    }}
    )
  }
  getTodaysDeliveryHistoryDataExport(start : any , length : any ,from_date : any ,to_date : any,search : any, dtype : any , status : any):Observable<any>{

    // console.log(localStorage.getItem('userData'));

    let pharmacyData = JSON.parse(localStorage.getItem('userData') || '{}')
    return this.http.get(this.API_URL+'delivery/dashboard/'+pharmacyData.pharmacy_id,{
      params: {
        // start,
        // length,
        // from_date,
        // to_date
        start,
        length,
        from_date,
        to_date,
        search,
        dtype,
        status
    }}
    )
  }


  getUserByName(userName : any):Observable<any>{

    let pharmacyData = JSON.parse(localStorage.getItem('userData') || '{}')
    return this.http.get(this.API_URL+'user/'+pharmacyData.pharmacy_id + '/' + userName
    )
  }
  getUserAddressList(userId : any):Observable<any>{

    let pharmacyData = JSON.parse(localStorage.getItem('userData') || '{}')
    return this.http.get(this.API_URL+'user/address' + '/' + userId
    )
  }
  getUserDelivery(deliveryId : any):Observable<any>{

    let pharmacyData = JSON.parse(localStorage.getItem('deliveryData') || '{}')
    return this.http.get(this.API_URL+'delivery' + '/' + deliveryId
    )
  }
  getMyCustomerData(start : any , length : any ,searchedName : any ,from_date : any , to_date : any):Observable<any>{

    // console.log(localStorage.getItem('userData'));

    let pharmacyData = JSON.parse(localStorage.getItem('userData') || '{}')
    return this.http.get(this.API_URL+'user/pharmacy/'+pharmacyData.pharmacy_id,{
      params: {
        start,
        length,
        search : searchedName,
        from_date,
        to_date
    }}
    )
  }

  getRecurringDeliveriesData(start : any , length : any ,searchedName : any ,from_date : any , to_date : any):Observable<any>{

    // console.log(localStorage.getItem('userData'));

    let pharmacyData = JSON.parse(localStorage.getItem('userData') || '{}')
    return this.http.get(this.API_URL+'delivery/pharmacy/recurrent/'+pharmacyData.pharmacy_id,{
      params: {
        start,
        length,
        search : searchedName,
        from_date,
        to_date
    }}
    )
  }
  getPaymentReportData(start : any , length : any ,searchedName : any ,status : any ,from_date : any , to_date : any):Observable<any>{

    // console.log(localStorage.getItem('userData'));

    let pharmacyData = JSON.parse(localStorage.getItem('userData') || '{}')
    return this.http.get(this.API_URL+'delivery/pharmacy/amount/'+pharmacyData.pharmacy_id,{
      params: {
        start,
        length,
        search : searchedName,
        status ,
        from_date,
        to_date
    }}
    )
  }

  addCustomer(data:any): Observable<any>{
    return this.http.post(this.API_URL + "user",data)
  }
  updateCustomer(data:any , userId : any): Observable<any>{
    return this.http.post(this.API_URL + "user/"+userId,data)
  }
  deleteCustomer(data:any , userId : any): Observable<any>{
    return this.http.post(this.API_URL + "user/"+userId,data)
  }
  addOrder(data:any): Observable<any>{
    console.log(data);
    return this.http.post(this.API_URL + "delivery",data)
  }
  updateOrder(data:any , id:any): Observable<any>{
    return this.http.post(this.API_URL + "delivery/"+id,data)
  }
  getReccuranceList():Observable<any>{
    return this.http.get(this.API_URL+'pharmacy/recurrence');
  }
  getPinCode(lat : any , lng : any):Observable<any>{
    return this.http.get('http://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyDi-I1VTLv53R_tkevQR36692dqrlEPDSA&latlng='+lat+','+lng+'&sensor=true');
  }
  getPreferanceList():Observable<any>{
    let pharmacyData = JSON.parse(localStorage.getItem('userData') || '{}')
    return this.http.get(this.API_URL+'pharmacy/preference');
  }

  getTagList():Observable<any>{
    return this.http.get(this.API_URL+'pharmacy/tags');
  }
  getDashboardData():Observable<any>{
    let pharmacyData = JSON.parse(localStorage.getItem('userData') || '{}')
    return this.http.get(this.API_URL+'pharmacy/dashboard/'+pharmacyData.pharmacy_id,{
      params: {
        calendar : 'TODAY'
    }});
  }
  getPaymentCollectedData(length : any):Observable<any>{
    let pharmacyData = JSON.parse(localStorage.getItem('userData') || '{}')
    return this.http.get(this.API_URL+'delivery/pharmacy/amount/'+pharmacyData.pharmacy_id,{
      params: {
        start : "0",
        length,
        status : '0'
        // search : searchedName,
        // from_date,
        // to_date
    }});
  }
  getDeclineDeliveriesData():Observable<any>{
    let pharmacyData = JSON.parse(localStorage.getItem('userData') || '{}')
    return this.http.get(this.API_URL+'delivery/dashboard/'+pharmacyData.pharmacy_id,{
      params: {
        // start,
        // length,
        // from_date,
        // to_date
        start : "0",
        length : "5",
        status : "12"
    }}
    )
  }
  getDriverChangedData():Observable<any>{
    let pharmacyData = JSON.parse(localStorage.getItem('userData') || '{}')
    return this.http.get(this.API_URL+'delivery/dashboard/'+pharmacyData.pharmacy_id,{
      params: {
        // start,
        // length,
        // from_date,
        // to_date
        start : "0",
        length : "5",
        status : "4"
    }}
    )
  }
  getDeliveriesCompletedData():Observable<any>{
    let pharmacyData = JSON.parse(localStorage.getItem('userData') || '{}')
    return this.http.get(this.API_URL+'delivery/dashboard/'+pharmacyData.pharmacy_id,{
      params: {
        // start,
        // length,
        // from_date,
        // to_date
        start : "0",
        length : "5",
        status : "11"
    }}
    )
  }
  getPrescriptionCollectedData():Observable<any>{
    let pharmacyData = JSON.parse(localStorage.getItem('userData') || '{}')
    return this.http.get(this.API_URL+'delivery/dashboard/'+pharmacyData.pharmacy_id,{
      params: {
        // start,
        // length,
        // from_date,
        // to_date
        start : "0",
        length : "5",
        dtype : "1",
    }}
    )
  }
  getDeliveryHistoryData(ddelivery_id : any):Observable<any>{
    let pharmacyData = JSON.parse(localStorage.getItem('userData') || '{}')
    return this.http.get(this.API_URL+'delivery/statushistory/'+ddelivery_id);
  }
  getLastSevenDaysData(status : any):Observable<any>{
    let pharmacyData = JSON.parse(localStorage.getItem('userData') || '{}')
    return this.http.get(this.API_URL+'pharmacy/dashboard/'+pharmacyData.pharmacy_id,{
      params: {
        calendar : status,
        // length,
        // search : searchedName,
        // from_date,
        // to_date
    }});
  }
  getUserDetails(userId : any):Observable<any>{
    let pharmacyData = JSON.parse(localStorage.getItem('userData') || '{}')
    return this.http.get(this.API_URL+'user/'+userId);
  }
  getProgressBarData():Observable<any>{
    let pharmacyData = JSON.parse(localStorage.getItem('userData') || '{}')
    return this.http.get(this.API_URL+'pharmacy/dashboard/'+pharmacyData.pharmacy_id,{
      params: {
        calander : "TODAY",
        // length,
        // search : searchedName,
        // from_date,
        // to_date
    }});
  }
  // callApi(){
  //   return this.http.get("https://api-adresse.data.gouv.fr/reverse/?lon=34.0522346496582&lat=-118.24368286132812");
  //   // this.http.get()
  //   // const url = `https://api-adresse.data.gouv.fr/reverse/?lon=${Longitude}&lat=${Latitude}`
  //   //Call API
  // }

  getDeliveryBoyLocation():Observable<any>{
    let pharmacyData = JSON.parse(localStorage.getItem('userData') || '{}')
    return this.http.get(this.API_URL+'pharmacy/deliveryboy/'+pharmacyData.pharmacy_id);
  }
  // getDriverNameDetails():Observable<any>{
  //   let pharmacyData = JSON.parse(localStorage.getItem('userData') || '{}')
  //   return this.http.get(this.API_URL+'pharmacy/deliveryboy/'+pharmacyData.pharmacy_id);
  // }

  validateEmail(data : any):Observable<any>{
    let pharmacyData = JSON.parse(localStorage.getItem('userData') || '{}')
    // let dataTemp = {"username":"mypharma"}
    // let data1 = {"username":data}
    return this.http.post(this.API_URL+'pharmacy/retrivepwd' , data);
  }
  validateOTP(data : any):Observable<any>{
    return this.http.post(this.API_URL+'pharmacy/validateotp' , data);
  }
  confirmPass(data : any):Observable<any>{
    return this.http.post(this.API_URL+'pharmacy/updatepwd' , data);
  }
  deleteDeliveries(data : any):Observable<any>{
    return this.http.post(this.API_URL+'delivery/delete/'+data,'');
  }

  getDeliveryLabel(pdfURL : any):Observable<any>{
    console.log('pdfURLpdfURLpdfURLpdfURL',pdfURL)
    return this.http.get(pdfURL);
  }
  uploadFile(file : any):Observable<any>{
    let pharmacyData = JSON.parse(localStorage.getItem('userData') || '{}')
    const uploadData = new FormData();
    uploadData.append('file', file);
    return this.http.post(this.API_URL + "delivery/uploadfile/"+pharmacyData.pharmacy_id, uploadData)

  }

  downloadDeliveryLabel():Observable<any>{
    let pharmacyData = JSON.parse(localStorage.getItem('userData') || '{}')
    return this.http.get(this.API_URL+'delivery/printlabel/'+pharmacyData.pharmacy_id);
  }
  checkDownloadLabel():Observable<any>{
    let pharmacyData = JSON.parse(localStorage.getItem('userData') || '{}')
    return this.http.get(this.API_URL+'delivery/downloadprintlabel/'+pharmacyData.pharmacy_id);
  }
  updateDownloadLabel():Observable<any>{
    let pharmacyData = JSON.parse(localStorage.getItem('userData') || '{}')
    return this.http.get(this.API_URL+'delivery/updateprintlabel/'+pharmacyData.pharmacy_id);
  }
  checkHost() {
    let host = !window.location.host ? 'theclick' : window.location.host
 //host='partner.theclick.ca';
  let result = host.includes("theclick");
  console.log(result)
  this.isGetMyRx=true
    if(result){
      this.isGetMyRx=false;
      this.titleService.setTitle("The Click Admin");
      // let favIcon: HTMLLinkElement = document.querySelector('#favIcon'); 
      // favIcon.href = `assets/images/Click_Logo.png`; 
      this.favIcon.href = `assets/images/Click_Logo.png`; 
    }
    return this.isGetMyRx
  }
}
